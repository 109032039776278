const itemsWork = [
    {
        key: "8",
        header: "SberMarketing",
        speciality: "Старший дизайнер",
        years: "2024–н.в.",
        description: "Создание креативных мастер-макетов и ресайзов в форматах jpg, png, gif, html5, mp4. Визирование, координация и учет работы подрядчиков. Проведение мастер-классов по работе с html5. Поддержание и развитие фирменного стиля компании. Разработка автоматизированной системы учета и хранения работ подрядчиков. Разработка и внедрение базы креативных материалов агентства. Разработка и внедрение регламента по работе с креативным отделом. Оптимизация создания ресайзов путем создания автоматизированных шаблонов.",
        width: "150",
    },
    {
        key: "7",
        header: "Media 108",
        speciality: "Ведущий дизайнер",
        years: "2023–2024",
        description: "Создание креативных мастер-макетов. Создание ресайзов в форматах jpg, png, gif, html5, mp4. Обучение Младших дизайнеров основам дизайна. Создание ежемесячного дизайн-дайджеста. Поддержание и развитие фирменного стиля компании.",
        width: "150",
    },
    {
        key: "6",
        header: "Selective Team",
        speciality: "Дизайнер",
        years: "2022–2023",
        description: "Самозанятость. Разработка онлайн-баннеров для разных клентов агентства. Дизайн и верстка лендингов",
        width: "150",
    },
    {
        key: "5",
        header: "Boiron",
        speciality: "Дизайнер",
        years: "2022–2023",
        description: "Самозанятость. Дизайн и верска изображений для продуктовых полок компании на сайтах-агрегаторах и сайтах аптек. Развитие фирменных стилей препаратов",
        width: "208",
    },
    {
        key: "4",
        header: "БНА “Траст”",
        speciality: "Дизайнер",
        years: "2019–2023",
        description: "Самозанятость. Подготовка анимационных макетов и полиграфической продукции для мероприятий компании. Поддержание и развитие фирменного стиля компании. Подготовка макетов для внутренних социальных сетей компании. Верстка и дизайн презентаций",
        width: "165",
    },
    {
        key: "3",
        header: "CG MOVIE",
        speciality: "Ведущий дизайнер",
        years: "2017–2023",
        description: "Дизайн, верстка и анимация креативных макетов для наружной рекламы, онлайн-баннеров и прессы. Дизайн и верстка презентаций и темплейтов. Верстка HTML-баннеров и рассылок. Монтаж и анимация видео. Создание иллюстраций для социальных сетей компании и их ведение. Разработка логотипов, брендбуков и айдентики. Дизайн и верстка буклетов, листовок и журналов. Подготовка анимационных макетов и полиграфической продукции для мероприятий компании. Поддержание и развитие фирменного стиля компании",
        width: "177",
    },
    {
        key: "2",
        header: "ГБОУ Школа №67",
        speciality: "Преподаватель дизайна",
        years: "2013–2015",
        description: "Ведение дополнительного спецкурса 'Дизайн' для учеников 8 и 9 класса. Составление программы, проведение уроков, проведение тестов и экзаменов",
        width: "40",
    },
    {
        key: "1",
        header: "CG TWIGA, ЗдравПункт",
        speciality: "Дизайнер",
        years: "2014–2017",
        description: "Разработка креативных макетов для прессы, наружной рекламы, интернет-баннеров. Разработка логотипов, брендбуков и айдентики. Дизайн и верстка буклетов, листовок и журналов. Создание раскадровок для видео-рекламы. Дизайн, верстка и анимация презентаций и темплейтов",
        width: "20",
    },
  ]

  export default itemsWork;